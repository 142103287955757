<template>
  <div class="Reporting">
    <div class="container">
      <el-card>
        <el-tabs v-model="queryInfo.reportingStatusParam" @tab-click="changeSearchangeSearch">
          <el-tab-pane label="待审核" name="0">
            <el-form ref="form" :model="queryInfo" label-width="80px">
              <div class="form">
                <div class="comble_txt">
                  <el-form-item label="报备机构:" prop="">
                    <el-radio-group
                      v-model="queryInfo.partnerName"
                      @change="changeSearchangeSearch"
                    >
                      <el-radio-button label="">全部</el-radio-button>
                      <el-radio-button
                        :label="item.partnerName"
                        v-for="(item, index) in InformationTypes"
                        :key="index"
                        >{{ item.partnerName }}</el-radio-button
                      >
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="所在地区:" prop="">
                    <el-select
                      v-model="queryInfo.province"
                      placeholder="请选择省份"
                      @change="citychange"
                    >
                      <el-option :value="''" label="全部"></el-option>
                      <el-option
                        v-for="item in operatingList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                    <div style="display: inline-block; width: 10px"></div>
                    <el-select
                      v-model="queryInfo.city"
                      placeholder="请选择地区"
                      @change="districtchange"
                    >
                      <el-option :value="''" label="全部"></el-option>
                      <el-option
                        v-for="item in operatingLists"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                    <div style="display: inline-block; width: 10px"></div>
                    <el-select
                      v-model="queryInfo.district"
                      placeholder="请选择区域"
                      @change="search"
                    >
                      <el-option :value="''" label="全部"></el-option>
                      <el-option
                        v-for="item in areaList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </el-form>
            <div class="search">
              <div>
                <el-button plain style="margin-right: 5px" @click="checkClick">批量审核</el-button>
                <el-button plain style="margin-right: 5px" @click="clickRecording"
                  >导出补录名单</el-button
                >
              </div>

              <div class="search_right">
                <el-input
                  placeholder="请输入企业全称、用户名称"
                  v-model="queryInfo.queryConditions"
                  class="input-with-select"
                  clearable
                >
                  <el-button slot="append" @click="changeSearchangeSearch">搜索</el-button>
                </el-input>
              </div>
            </div>
            <el-table
              v-loading="loading"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              class="table_data"
              :data="tableData"
              :header-cell-style="{
                background: '#F8F9FB',
                color: '#595959',
                'text-align': 'center'
              }"
              ref="multipleTable"
              style="width: 100%"
              tooltip-effect="dark"
              :row-key="getRowKey"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
                :reserve-selection="true"
              />
              <el-table-column prop="reportId" label="编号" align="center" width="120">
              </el-table-column>
              <el-table-column label="logo" align="center">
                <template v-slot="{ row }">
                  <img v-if="row.companyLogo" :src="row.companyLogo" alt="" class="img" />
                </template>
              </el-table-column>
              <el-table-column prop="companyFullName" label="企业全称" width="300" align="center">
                <template v-slot="{ row }">
                  <div>{{ row.companyFullName }}</div>

                  <div style="color: #ff7878; float: left" v-if="row.companyReportStatus">
                    <i class="el-icon-warning"></i>
                    注意：这家企业有多家机构申请报备
                    <span @click="getCompanyDetail(row)" class="warning_color"
                      >【查看报备名单】</span
                    >
                  </div>
                  <div style="color: #ff7878; float: left" v-if="row.partnerNameStatus">
                    <i class="el-icon-warning"></i>
                    注意：这家企业已经被机构关联
                  </div>
                  <div style="color: #ff7878; float: left" v-if="!row.dataComId">
                    <i class="el-icon-warning"></i>
                    注意：该企业需要补录在数据系统中
                  </div>
                </template>
              </el-table-column>

              <el-table-column prop="city" label="所在地区" align="center">
                <template v-slot="{ row }">
                  <span>{{ row.province }}-{{ row.city }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="partnerName" label="报备机构" align="center">
              </el-table-column>
              <el-table-column label="客户状态" width="200" align="center">
                <template v-slot="{ row }">
                  <span class="customer_status huibg" v-if="!row.partnerChannelId">未绑定</span>
                  <span class="customer_status" v-else>已绑定</span>
                  <span class="customer_status huibg" v-if="row.establishWechatGroup == 0"
                    >未建立</span
                  >
                  <span class="customer_status" v-if="row.establishWechatGroup == 1">已建群</span>
                  <span class="customer_status redbg" v-if="row.establishWechatGroup == 2"
                    >撤销</span
                  >
                </template>
              </el-table-column>

              <el-table-column
                prop="reportTime"
                label="报备时间"
                sortable
                width="320"
                align="center"
              >
                <template v-slot="{ row }">
                  <div class="time_box">
                    <div>{{ row.reportTime | date }}</div>
                    <div>{{ row.reportTime | time }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="160">
                <template v-slot="{ row }">
                  <div class="btn">
                    <span class="pass" @click="passClick(row)">通过</span>
                    <span class="un_pass" @click="unPassClick(row)">不通过</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="已审核" name="1">
            <el-form ref="form" :model="queryInfo" label-width="80px">
              <div class="form">
                <div class="comble_txt">
                  <el-form-item label="报备机构:" prop="">
                    <el-radio-group v-model="queryInfo.partnerName" @change="search">
                      <el-radio-button label="">全部</el-radio-button>
                      <el-radio-button
                        :label="item.partnerName"
                        v-for="(item, index) in InformationTypes"
                        :key="index"
                        >{{ item.partnerName }}</el-radio-button
                      >
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="审核状态:" prop="">
                    <el-radio-group v-model="queryInfo.reportingStatus" @change="search">
                      <el-radio-button label="">全部</el-radio-button>
                      <el-radio-button label="2">审核通过</el-radio-button>
                      <el-radio-button label="3">审核不通过</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="所在地区:" prop="">
                    <el-select
                      v-model="queryInfo.province"
                      placeholder="请选择省份"
                      @change="citychange"
                    >
                      <el-option :value="''" label="全部"></el-option>
                      <el-option
                        v-for="item in operatingList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                    <div style="display: inline-block; width: 10px"></div>
                    <el-select
                      v-model="queryInfo.city"
                      placeholder="请选择地区"
                      @change="districtchange"
                    >
                      <el-option :value="''" label="全部"></el-option>
                      <el-option
                        v-for="item in operatingLists"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                    <div style="display: inline-block; width: 10px"></div>
                    <el-select
                      v-model="queryInfo.district"
                      placeholder="请选择区域"
                      @change="search"
                    >
                      <el-option :value="''" label="全部"></el-option>
                      <el-option
                        v-for="item in areaList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </el-form>
            <div class="search">
              <div class="search_right">
                <el-input
                  placeholder="请输入企业全称、用户名称"
                  v-model="queryInfo.queryConditions"
                  class="input-with-select"
                  clearable
                >
                  <el-button slot="append" @click="changeSearchangeSearch">搜索</el-button>
                </el-input>
              </div>
            </div>
            <el-table
              v-loading="loading"
              element-loading-spinner="el-icon-loading"
              class="table_data"
              :data="tableData"
              :header-cell-style="{
                background: '#F8F9FB',
                color: '#595959',
                'text-align': 'center'
              }"
              ref="multipleTable"
              style="width: 100%"
              tooltip-effect="dark"
              :row-key="getRowKey"
              @sort-change="changeSort"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
                :reserve-selection="true"
              />
              <el-table-column prop="reportId" label="编号" align="center" width="100">
              </el-table-column>
              <el-table-column label="logo" align="center">
                <template v-slot="{ row }">
                  <img v-if="row.companyLogo" :src="row.companyLogo" alt="" class="img" />
                </template>
              </el-table-column>
              <el-table-column prop="companyFullName" label="企业全称" align="center" width="350">
                <template v-slot="{ row }">
                  <div>{{ row.companyFullName }}</div>
                  <div style="color: #ff7878; float: left" v-if="row.companyReportStatus">
                    <i class="el-icon-warning"></i>
                    注意：这家企业有多家机构申请报备
                    <span @click="getCompanyDetail(row)" class="warning_color"
                      >【查看报备名单】</span
                    >
                  </div>
                  <div style="color: #ff7878; float: left" v-if="row.partnerNameStatus">
                    <i class="el-icon-warning"></i>
                    注意：这家企业已经被机构关联
                  </div>
                  <div style="color: #ff7878; float: left" v-if="!row.dataComId">
                    <i class="el-icon-warning"></i>
                    注意：该企业需要补录在数据系统中
                  </div>
                </template>
              </el-table-column>

              <el-table-column prop="city" label="所在地区" align="center">
                <template v-slot="{ row }">
                  <span>{{ row.province }}-{{ row.city }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="partnerName" label="报备机构" align="center">
              </el-table-column>

              <el-table-column label="审核状态" align="center">
                <template v-slot="{ row }">
                  <span v-if="row.reportingStatus == 1">报备中</span>
                  <span v-if="row.reportingStatus == 2">报备成功</span>
                  <span v-if="row.reportingStatus == 3">报备失败</span>
                  <span v-if="row.reportingStatus == 4">审核成功，报备中</span>
                  <span v-if="row.reportingStatus == 5">审核成功，报备失败</span>
                  <span v-if="row.reportingStatus == 6">报备失败，重新报备</span>
                </template>
              </el-table-column>
              <el-table-column prop="refuseReason" label="审核理由" align="center">
              </el-table-column>

              <el-table-column
                prop="reportTime"
                label="报备时间"
                sortable
                width="120"
                align="center"
              >
                <template v-slot="{ row }">
                  <div class="time_box">
                    <div>{{ row.reportTime | date }}</div>
                    <div>{{ row.reportTime | time }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="updateTime"
                label="审核时间"
                sortable
                width="120"
                align="center"
              >
                <template v-slot="{ row }">
                  <div class="time_box">
                    <div>{{ row.updateTime | date }}</div>
                    <div>{{ row.updateTime | time }}</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 拒绝的理由弹窗 -->
    <el-dialog title="不通过" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="refuse">
        <el-form
          :model="refuseObj"
          :rules="rules"
          ref="ruleForm"
          label-width="60px"
          class="demo-ruleForm"
        >
          <el-form-item label="理由" prop="refuseReason">
            <el-input
              type="textarea"
              :rows="10"
              placeholder="请输入不通过理由"
              v-model="refuseObj.refuseReason"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="报备该企业合作伙伴名单"
      :visible.sync="dialogVisiblecompany"
      width="30%"
      :before-close="handleClose"
    >
      <div class="dialog_top">
        <div class="company_top">
          <img :src="companyinfo.companyLogo" alt="" />
          <div style="margin-left: 10px">
            <div class="companyFullName">
              <span>{{ companyinfo.companyFullName }}</span>
            </div>
            <div>
              <span>{{ companyinfo.legalPerson }}</span>
              <span>{{ companyinfo.companyPhone }}</span>
            </div>
          </div>
        </div>
        <div class="company_center" v-for="(item, index) in reportList" :key="index">
          <div style="position: relative; top: -2px; color: #212121">
            <span>{{ index + 1 }}</span>
            <img class="coverImage" :src="item.coverImage" alt="" />
            <span style="margin-left: 5px">{{ item.partnerName }}</span>
          </div>
          <div>
            <!--            <span style="color: #ff8c06" v-if="item.reportingStatus == 1">报备中</span>-->
            <!--            <span style="color: #f53939" v-if="item.reportingStatus == 3">报备失败</span>-->
            <!--            <span v-if="item.reportingStatus == 2">{{ item.reportTime }}</span>-->
            <span style="color: #ff8c06" v-if="item.reportingStatus == 1">报备中</span>
            <span style="color: #f53939" v-if="item.reportingStatus == 2"
              >报备时间{{ item.reportTime }}</span
            >
            <span v-if="item.reportingStatus == 3">报备失败</span>
            <span style="color: #333333" v-if="item.reportingStatus == 4"
              >审核成功，报备中:{{ item.reportTime }}</span
            >
            <span v-if="item.reportingStatus == 5">审核成功，报备失败:{{ item.reportTime }}</span>
            <span v-if="item.reportingStatus == 6">报备失败，重新报备:{{ item.reportTime }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog_footer">
        <el-button type="primary" @click="canlesreport">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getReportPage,
  applyNoPass,
  getCompanyDetail,
  getCompanyBindReport,
  applyPass,
  reportPassList,
  getReportOrganization,
  exportCompanyReportExcel
} from '@/api/datement'
import { pagination } from '@/mixins/pagination'
import { getProvince } from '@/api/demand'
const defaultQueryInfo = Object.freeze({
  city: '',
  district: '', //所在区
  industry: [], //所属行业
  orderByParam: 't.report_time DESC', //排序字段+ DESC或ASC
  pageNum: 1,
  pageSize: 10,
  partnerName: '', //报备机构
  province: '', //所在省份
  queryConditions: '', //模糊查询字段
  reportingStatusParam: 0 //报备状态 传0是未审核 1是已审核
})
const defaultRefuseObj = Object.freeze({
  id: '', //当前用户的id
  refuseReason: '' //拒绝的理由
})
export default {
  name: 'Reporting',
  mixins: [pagination],
  data() {
    return {
      rules: {
        refuseReason: [{ required: true, message: '请输入不通过理由', trigger: 'blur' }]
      }, //提交理由的验证表单
      paramrules: {
        monthNum: [{ required: true, message: '请勾选账号周期', trigger: 'change' }]
      },
      areaList:[],
      ids: [],
      dialogVisible: false, //拒绝理由的弹窗
      open: false, //通过窗口
      refuseObj: { ...defaultRefuseObj },
      // 遮罩层
      loading: true,
      tableData: [], //表格数据
      queryInfo: { ...defaultQueryInfo },
      InformationTypes: [],
      companyinfo: {}, //企业详情
      reportList: [], //报备列表
      dialogVisiblecompany: false, //查看企业报备列表
      operatingList: [], //省
      operatingLists: [] //市
    }
  },
  created() {
    //页面加载完成  调取数据
    this.search()
    this.getReportOrganization()
    this.incity()
  },
  methods: {
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.queryInfo.province == o.name) {
          this.queryInfo.city = ''
          if (item == '全部') {
            this.queryInfo.pageNum = 1
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.queryInfo.pageNum = 1
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          }
        }
      })
      // this.search()
    },
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.queryInfo.city == o.name) {
          this.queryInfo.district = ''
          if (item == '全部') {
            this.areaList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
            // console.log(this.areaList, 'this.areaList ')
          } else {
            this.areaList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
    },
    // citychange(item) {
    //   this.operatingList.forEach((o) => {
    //     if (this.queryInfo.province == o.name) {
    //       this.queryInfo.city = ''
    //       if (item == '全部') {
    //         this.queryInfo.pageNum = 1
    //         this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
    //       } else {
    //         this.queryInfo.pageNum = 1
    //         this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
    //       }

    //       console.log(item, 'item', this.operatingList)
    //       // this.getByFatherIdt(o.code)
    //     }
    //   })
    //   // this.search()
    // },
    //查询报备中心的报备机构
    async getReportOrganization() {
      const { data: res } = await getReportOrganization()
      if (res.resultCode == 200) {
        this.InformationTypes = res.data
      }
    },
    //查看报备列表
    async getCompanyBindReport(id) {
      const query = {
        id
      }
      const { data: res } = await getCompanyBindReport(query)
      if (res.resultCode == 200) {
        this.reportList = res.data
      }
    },
    //取消报备列表弹窗
    canlesreport() {
      this.dialogVisiblecompany = false
    },
    //客户企业详情
    async getCompanyDetail(row) {
      this.dialogVisiblecompany = true
      this.getCompanyBindReport(row.companyId)
      const query = {
        id: row.companyId
      }
      const { data: res } = await getCompanyDetail(query)
      if (res.resultCode == 200) {
        this.companyinfo = res.data
      }
    },

    // 补录名单 exportCompanyReportExcel
    async exportRoster() {
      // if(!this.ids.length){
      //   this.$message.error('请选择需要导出的信息!')
      //   return
      // }
      // this.loading = true
      // const query = {
      //   activityId: this.activityId,
      //   ids: this.ids
      // }
      const res = await exportCompanyReportExcel()
      if (res.data) {
        this.loading = false
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `报备名单.xlsx` //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
        this.search()
      } else {
        this.$message.error('下载失败!')
      }
    },
    //批量审核
    checkClick() {
      if (this.ids.length > 0) {
        const that = this
        this.$alert('是否确认通过审核？')
          .then(function () {
            return reportPassList(that.ids)
          })
          .then(() => {
            that.search()
            that.ids = []
            that.$refs.multipleTable.clearSelection()
            that.$message.success('审核成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择资讯')
      }
    },
    // 导出补录名单
    clickRecording() {
      this.exportRoster()
    },
    //提交按钮拒绝理由
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const { data: res } = await applyNoPass(this.refuseObj)
          if (res.resultCode === 200) {
            this.$message.success('提交成功')
            this.search()
            this.dialogVisible = false
          } else {
            this.$message.error('拒绝失败')
          }
        } else {
          this.$message.error('请输入拒绝理由')
        }
      })
    },

    //通过审核
    passClick(row) {
      const query = {
        id: row.reportId
      }
      const that = this
      this.$alert('您是否确认审核？')
        .then(function () {
          return applyPass(query)
        })
        .then(() => {
          this.search()
          this.$message.success('审核成功')
        })
        .catch(() => {})
    },

    //不通过审核
    unPassClick(row) {
      this.refuseObj.id = row.reportId
      this.dialogVisible = true
      this.dialogVisiblecompany = false
    },
    //关闭不通过审核弹窗、
    handleClose() {
      this.dialogVisible = false
      this.dialogVisiblecompany = false
      this.refuseObj = { ...defaultRefuseObj }
    },
    //多选
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.reportId)
    },
    //保存首页勾选数据
    getRowKey(row) {
      return row.reportId
    },
    //获取表格数据
    async search() {
      this.loading = true
      const { data: res } = await getReportPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'applyTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 'y.apply_time ASC'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 'y.apply_time DESC'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = 'y.apply_time DESC'
          this.search()
        }
      }
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .refuse .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 300px;
}
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 0;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: none !important;
}
.Reporting {
  width: 100%;
  .dialog_footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .dialog_top {
    .company_top {
      display: flex;
      align-items: center;
      .companyFullName {
        font-size: 18px;
        font-weight: 550;
        color: #151515;
        padding-bottom: 15px;
      }
      img {
        width: 58.68px;
        height: 57.38px;
        border-radius: 5px;
      }
    }

    .company_center {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      height: 38px;
      background: #f4f4f4;
      border-radius: 1px;
      .coverImage {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        margin-left: 20px;
        position: relative;
        top: 4px;
      }
    }
  }
  .container {
    height: calc(100vh - 150px);
    overflow: auto;
  }
  /deep/ .el-tabs__item {
    font-size: 20px;
    color: #999999;
  }

  /deep/ .el-tabs__item.is-active {
    color: #4e93fb !important;
  }

  .table_data {
    .warning_color {
      cursor: pointer;
      color: #ff8c06;
    }
    .success {
      color: #ff7d18;
      font-size: 14px;
      font-weight: 400;
    }

    .error {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;

      p {
        color: #f53939;
        font-size: 14px;
        font-weight: 400;
      }

      .span {
        color: #999999;
        font-size: 12px;
        display: inline-block;
        width: 100%;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .new_span {
        color: #999999;
        line-height: 1.5em;
        font-size: 12px;
        cursor: pointer;
        font-weight: 400;
      }
    }

    .img {
      width: 42px;
      height: 42px;
      display: block;
      margin: 0 auto;
      border-radius: 4px;
    }

    .type_can {
      display: flex;
      flex-direction: row;
      align-items: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #ff7d18;
        border-radius: 50%;
      }

      span {
        color: #ff7d18;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .type_xuan {
      display: flex;
      flex-direction: row;
      align-items: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #001aff;
        border-radius: 50%;
      }

      span {
        color: #001aff;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .formal {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(78, 147, 251, 0.1);
      color: #4e93fb;
      border-radius: 2px;
    }

    .tourist {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 125, 24, 0.1);
      color: #ff7d18;
      border-radius: 2px;
    }

    .img_list {
      .new_img {
        width: 70px;
        height: 40px;
        border-radius: 4px;
        margin-right: 10px;
      }
    }

    .btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .pass {
        cursor: pointer;
        border: 1px solid #4e93fb;
        color: #4e93fb;
        height: 24px;
        box-sizing: border-box;
        display: block;
        border-radius: 4px;
        width: 50px;
        line-height: 24px;
        margin-right: 10px;
        text-align: center;
      }

      .un_pass {
        border: 1px solid #fb6956;
        color: #fb6956;
        height: 24px;
        cursor: pointer;
        box-sizing: border-box;
        display: block;
        border-radius: 4px;
        width: 50px;
        text-align: center;
        line-height: 24px;
      }
    }
  }

  .search {
    width: 100%;
    overflow: hidden;
    display: flex;
    margin: 20px 0;
    justify-content: flex-end;
    justify-content: space-between;
  }

  .search_right {
    width: 560px;

    /deep/ .el-button {
      border: 1px solid #448aff;
      color: #fff;
      border-radius: 0;
      background-color: #448aff;
    }
  }

  .new_page {
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }

  .dialog_btn {
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.customer_status {
  background: #48dc5f;
  width: 46px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  border-radius: 9px;
  display: inline-block;
  margin: 0 10px;
}
.yellowbg {
  background: #ffa24d;
}

.redbg {
  background: #ff4d4d;
}

.bluebg {
  background: #24b6f4;
}

.huibg {
  background: #999999;
}
</style>
